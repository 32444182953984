<template>
  <main :class="$style['main']">
    <slot />
  </main>
</template>
<style module>
.main {
  /* align-items: center; */
  justify-content: center;
  background-color: var(--background-base);
  display: flex;
  color: var(--text-base);
  min-height: 100vh;
}
</style>
